.MainContainer {
	background: "#222"; 
} 

.App-logo {
	height: 40vmin;
	pointer-events: none;
}


.arrow {
	/* From https://css.glass */
	background: rgba(255, 255, 255, 0.3);
	backdrop-filter: blur(10px); 
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
	-webkit-backdrop-filter: blur(10px);
	border: 1px solid rgba(255, 255, 255, 0.3);
}

.arrow:hover {
	/* From https://css.glass */
	background: rgba(255, 255, 255, 0.6);
	backdrop-filter: blur(10px); 
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
	-webkit-backdrop-filter: blur(10px);
	border: 1px solid rgba(255, 255, 255, 0.3);
}


.glass {
	/* From https://css.glass */
	background: rgba(255, 255, 255, 0.5);
	backdrop-filter: blur(10px); 
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
	-webkit-backdrop-filter: blur(10px);
	border: 1px solid rgba(255, 255, 255, 0.3);
}

.glassNoShadow {
	/* From https://css.glass */
	backdrop-filter: blur(14px); 
	-webkit-backdrop-filter: blur(14px);
	border: 1px solid rgba(255, 255, 255, 0.3);
}

.glassDark {
	/* From https://css.glass */
	background: rgba(1, 1, 1, 0.2);
	color:black;
	backdrop-filter: blur(14px); 
	box-shadow: 0 4px 10px rgba(50, 100, 100, 0.1);
	-webkit-backdrop-filter: blur(14px);
	border: 1px solid rgba(1, 1, 1, 0.1);
}


.glassDarkNoShadow {
	/* From https://css.glass */
	background: rgba(1, 1, 1, 0.2);
	backdrop-filter: blur(14px); 
	-webkit-backdrop-filter: blur(14px);
	border: 1px solid rgba(1, 1, 1, 0.1);
}

.react-horizontal-scrolling-menu--wrapper  {
	width:"200px" !important;
}

.scrollContainer { 
	backgroun-color:"transparent" !important;  
	display:"none" !important;
}
.animate {
	animation-duration: 0.75s;
	animation-delay: 0.5s;
	animation-name: animate-fade;
	animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
}

.card {
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}

.card:hover {
	box-shadow: 0 4px 20px rgba(0, 0, 200, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
	animation-name: animate-glow;
	animation-timing-function: ease;
  }
  
/* Glow In */
.animate.glow {
	animation-name: animate-glow;
	animation-timing-function: ease;
}

@keyframes animate-glow {
	0% {
		opacity: 0.2;
		filter: brightness(3) saturate(3);
		transform: scale(0.8, 0.8);
	}
	100% {
		opacity: 0.7;
		filter: brightness(0.7) saturate(0.7);
		transform: scale(1, 1);
	}
}

.delay-1 {
	animation-delay: 0.6s;
}
.delay-2 {
	animation-delay: 0.7s;
}
.delay-3 {
	animation-delay: 0.8s;
}

.glassLight {
	/* From https://css.glass */
	/* From https://css.glass */
	background: rgba(255, 255, 255, 0.2);
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(5px);
	-webkit-backdrop-filter: blur(5px);
	border: 1px solid rgba(255, 255, 255, 0.3);
}

.glassHarder {
	/* From https://css.glass */
	/* From https://css.glass */
	background: rgba(255, 255, 255, 0.8) !important;
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(4px);
	-webkit-backdrop-filter: blur(4px);
	border: 1px solid rgba(255, 255, 255, 0.3);
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

.MuiBox {
	background-image: url("background.jpg") !important;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
